import React from "react"
import {
  TableContainer,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material"
import { format } from "date-fns"
import MetaTags from "react-meta-tags"
import { useSelector } from "react-redux"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useParams } from "react-router-dom"
export default function TradeTypeDetail() {
  let { id } = useParams()
  const { traderType } = useSelector(state => state?.TraderTypeReducer)
  const filterActivity = traderType.find(e => e?._id === id)
  const breadcrumbItems = [
    { title: "Dashboard", path: "/triptrader/dashboard" },
    { title: "Trade Types", path: "/triptrader/tradetype" },
    { title: "Trade Type Detail", path: "#" },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Trade Type Detail | Trip Trader</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
              <Breadcrumbs breadcrumbItems={breadcrumbItems} />

                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active"></li>
                </ol>
              </Col>
            </Row>
          </div>
          <Row>
            <Col xs={12} sm={12} md={6}>
              <Card>
                <Typography id="Pageheader"> General Information</Typography>
                <CardBody>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Posted By</TableCell>
                          <TableCell>
                            {format(
                              new Date(filterActivity?.createdAt),
                              "dd:MMM:Y, h:mm aaa"
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Updated By</TableCell>
                          <TableCell>
                            {format(
                              new Date(filterActivity?.updatedAt),
                              "dd:MMM:Y, h:mm aaa"
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <Card>
                <CardBody>
                  <Typography id="Pageheader">Trade Type Detail</Typography>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell id="tableHeadvalue">
                            Trade Type Name
                          </TableCell>
                          <TableCell sx={{ textTransform: "capitalize" }}>
                            {filterActivity?.name}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Status</TableCell>
                          <TableCell>
                            {filterActivity?.status === "active" ? (
                              <span className="badge bg-success">Active</span>
                            ) : (
                              <span className="badge bg-danger">Disabled</span>
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
