import React from "react"
import ReactApexChart from "react-apexcharts"

const TripsActionsStats = ({ data }) => {
  const chartData = {
    series: data ? [data?.active_trips, data?.suspened_trips] : [],
    options: {
      chart: {
        type: "pie",
        height: 230,
      },
      title: {
        text: "Created Trips Stats",
        align: "center",
        floating: false,
      },
      labels: ["Active Trips", "Suspended Trips"],
      colors: ["#283C2E", "#FF4B4B"],
      legend: {
        position: "bottom",
        offsetY: 0,
      },
    },
  }
  return (
    <div>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="pie"
        height={380}
      />
    </div>
  )
}

export default TripsActionsStats
