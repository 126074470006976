import React, { useEffect, useState } from "react"
import {
  Box,
  TableContainer,
  Typography,
  Table,
  TableRow,
  TableCell,
} from "@mui/material"
import { format } from "date-fns"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import backend from "api/api"
import { Container, Row, Col, Button } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useParams, Link, useHistory } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"

export default function StateEdit() {
  let { id } = useParams()
  const dispatch = useDispatch()
  let history = useHistory()
  const { state } = useSelector(state => state?.StateReducer)
  const filterState = state.find(e => e?._id === id)
  const [StateStatus, setStateStatus] = useState(filterState?.name)
  const getAllStates = async () => {
    try {
      const resp = await backend.get("/api/state")
      dispatch({
        type: "STATE_SUCCESS",
        payload: resp?.data.data,
      })
    } catch (error) {
      toast.error(error?.response?.data.message || error.message || error)
    }
  }
  const handleState = e => {
    setStateStatus(e.target.value)
  }
  const postState = async () => {
    try {
      const body = {
        name: StateStatus,
      }
      const resp = await backend.put(`/api/state/${id}`, body)
      toast.success(resp.data.message)
      setTimeout(() => history.push("/triptrader/state"), 1000)
    } catch (error) {
      toast.error(error.response.data.message || error.message || error)
    }
  }

  useEffect(() => {
    getAllStates()
  }, [])
  const breadcrumbItems = [
    { title: "Dashboard", path: "/triptrader/dashboard" },
    { title: "States", path: "/triptrader/state" },
    { title: "State Edit", path: "#" },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>State Edit | Trip Trader</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
              <Breadcrumbs breadcrumbItems={breadcrumbItems} />

                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active"></li>
                </ol>
              </Col>
            </Row>
          </div>

          <Row>
            <Col xs={12} sm={12} md={6}>
              <label className=" col-form-label">State Name</label>

              <select
                className="form-select"
                value={StateStatus}
                onChange={handleState}
              >
                {state?.map(e => (
                  <>
                    <option value={e?.name}>{e?.name}</option>
                  </>
                ))}
              </select>
            </Col>
          </Row>
          <Row
            style={{
              marginTop: "1rem",
            }}
          >
            <Col xs={4} sm={3} md={1}>
              <Button
                color="success"
                className="btn btn-success waves-effect waves-light"
                onClick={postState}
              >
                Save
              </Button>{" "}
              <ToastContainer />
            </Col>
            <Col xs={3} sm={3} md={6}>
              <Link
                to="/triptrader/state"
                color="secondary"
                className="btn btn-secondary waves-effect"
              >
                Cancel
              </Link>{" "}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
