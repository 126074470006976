import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
// users
import { logoutUser } from "store/actions"

import { toast, ToastContainer } from "react-toastify"
import { Avatar } from "@material-ui/core"
const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const history = useHistory()
  const [username, setusername] = useState("Admin")
  const dispatch = useDispatch()
  const { login } = useSelector(state => state.UserAuthReducer)
  const logout = async () => {
    dispatch(logoutUser(history, toast))
    dispatch({
      type: "LOGOUT_SUCCESS",
      payload: {},
    })
    toast.success("Log Out SuccessFully")
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <Avatar
            className="rounded-circle header-profile-user"
            src={login.image}
            alt="Header Avatar"
          />
          {/* <img
            className="rounded-circle header-profile-user"
            src={login.image}
            alt="Header Avatar"
          /> */}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link
            to="/triptrader/changepassword"
            className="dropdown-item"
            tag="a"
            href="auth-lock-screen"
          >
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {props.t("Change Password")}
          </Link>
          <div className="dropdown-divider" />
          <Link to="" onClick={logout} className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>Log Out</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
