import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Col, Row, Container, Button } from "reactstrap"
import backend from "api/api"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"

export default function Setting() {
  const dispatch = useDispatch()
  const { setting } = useSelector(state => state?.SettingReducer)
  const [filterSetting, setFilterSetting] = useState(setting)
  const history = useHistory()
  const getAllSetting = async () => {
    try {
      const resp = await backend.put("/api/setting/6331909d9a4f528f1b4cc616")
      dispatch({
        type: "SETTING_SUCCESS",
        payload: resp.data.data,
      })
    } catch (error) {
      toast.error(error?.response?.data.message || error?.message || error)
    }
  }

  const postAllSetting = async () => {
    try {
      const body = {
        phone: filterSetting?.phone,
        email: filterSetting?.email,
        address: filterSetting?.address,
      }

      const phoneRegex = /^\+?[1-9]\d{1,14}$/;
      if (!phoneRegex.test(body?.phone?.replaceAll('-',''))) {
        toast.error("Enter Correct Phone");
        return;
      }
      
      const resp = await backend.put(
        "/api/setting/6331909d9a4f528f1b4cc616",
        body
      )
      toast.success(resp.data.message)
      getAllSetting()
      setTimeout(() => history.push("/triptrader/subscribecustomer"), 1000)
    } catch (error) {
      toast.error(error.response?.data?.message || error?.message || error)
    }
  }
  useEffect(() => {
    getAllSetting()
  }, [])
  const breadcrumbItems = [
    { title: "Dashboard", path: "/triptrader/dashboard" },
    { title: "Settings", path: "#" },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Settings| Trip Traders</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
              <Breadcrumbs breadcrumbItems={breadcrumbItems} />

                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active"></li>
                </ol>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6}>
                <label
                  htmlFor="example-text-input"
                  className=" col-form-label"
                >
                  Address
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={filterSetting?.address}
                  onChange={e =>
                    setFilterSetting({
                      ...filterSetting,
                      address: e.target.value,
                    })
                  }
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <label
                  htmlFor="example-text-input"
                  className=" col-form-label"
                >
                  Email
                </label>
                <input
                  className="form-control"
                  type="email"
                  value={filterSetting?.email}
                  onChange={e =>
                    setFilterSetting({
                      ...filterSetting,
                      email: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6}>
                <label
                  htmlFor="example-text-input"
                  className=" col-form-label"
                >
                  Phone
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={filterSetting?.phone}
                  onChange={e =>
                    setFilterSetting({
                      ...filterSetting,
                      phone: e.target.value,
                    })
                  }
                  maxLength={18}
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col xs={4} sm={3} md={1}>
                <Button
                  color="success"
                  className="btn btn-success waves-effect waves-light"
                  onClick={postAllSetting}
                  disabled={!filterSetting?.phone || !filterSetting?.email || !filterSetting?.address}
                >
                  Save
                </Button>{" "}
                <ToastContainer />
              </Col>
              <Col xs={3} sm={3} md={6}>
                <Link
                  to="/triptrader/dashboard"
                  color="secondary"
                  className="btn btn-secondary waves-effect"
                >
                  Cancel
                </Link>{" "}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}
