import React from "react"
import {
  TableContainer,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material"
import { format } from "date-fns"
import MetaTags from "react-meta-tags"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Switch from "react-switch"
import { toast, ToastContainer } from "react-toastify"
import backend from "api/api"

export default function SpeciesDetail() {
  let { id } = useParams()
  const { species } = useSelector(state => state.SpeciesReducers)
  const { Category } = useSelector(state => state?.CategoryReducer)

  const { traderType } = useSelector(state => state?.TraderTypeReducer)
  const filterSpecies = species.find(e => e?._id === id)
  const filterType = traderType.find(e => e._id === filterSpecies?.type?._id)
  const filterCategory = Category?.find(
    e => e?._id === filterSpecies?.category?._id
  )
  console.log(filterCategory)
  console.log(filterSpecies)
  const dispatch = useDispatch()
  const getAllCategory = async () => {
    try {
      const resp = await backend.get("/api/category")
      console.log(resp)
      dispatch({
        type: "CATEGORY_SUCCESS",
        payload: resp.data.data,
      })
    } catch (error) {
      toast.error(error.response?.data.message || error?.message || error)
    }
  }

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    )
  }
  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    )
  }
  const getAllActivity = async () => {
    try {
      const resp = await backend.get("/api/type")
      dispatch({
        type: "TradeType_SUCCESS",
        payload: resp.data.data,
      })
    } catch (error) {
      toast.error(error.response?.data.message || error?.message || error)
    }
  }
  const changeStatus = async (status, id) => {
    try {
      const value = status == "active" ? "disabled" : "active"
      const body = {
        status: value,
      }
      const resp = await backend.put(`/api/type/${id}`, body)
      toast.success(resp?.data?.message)
      getAllActivity()
    } catch (error) {
      toast.error(error.response?.data?.message || error?.message || error)
    }
  }

  const handleCategoryStatus = async () => {
    const value = filterCategory?.status === "active" ? "disabled" : "active"
    const body = {
      status: value,
    }
    debugger
    const resp = await backend.put(`/api/category/${filterCategory?._id}`, body)
    debugger
    toast.success(resp?.data?.message)
    getAllCategory()
  }

  const breadcrumbItems = [
    { title: "Dashboard", path: "/triptrader/dashboard" },
    { title: "Species", path: "/triptrader/species" },
    { title: "Specie Detail", path: "#" },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Species Detail | Trip Trader</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
              <Breadcrumbs breadcrumbItems={breadcrumbItems} />
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active"></li>
                </ol>
              </Col>
            </Row>
          </div>
          <Row>
            <Col xs={12} sm={12} md={6}>
              <Card>
                <Typography id="Pageheader"> General Information</Typography>
                <CardBody>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Posted By</TableCell>
                          <TableCell>
                            {format(
                              new Date(filterSpecies?.createdAt),
                              "dd:MMM:Y, h:mm aaa"
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Updated By</TableCell>
                          <TableCell>
                            {format(
                              new Date(filterSpecies?.updatedAt),
                              "dd:MMM:Y, h:mm aaa"
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <Card>
                <CardBody>
                  <Typography id="Pageheader">Species Detail</Typography>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell id="tableHeadvalue">
                            Species Name
                          </TableCell>
                          <TableCell>{filterSpecies?.name}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Status</TableCell>
                          <TableCell>
                            {filterSpecies?.status === "active" ? (
                              <span className="badge bg-success">Active</span>
                            ) : (
                              <span className="badge bg-danger">Disabled</span>
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={6}>
              <Card>
                <CardBody>
                  <Typography id="Pageheader">Trade Type Detail</Typography>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Trade Type</TableCell>
                          <TableCell
                            sx={{
                              textTransform: "capitalize",
                            }}
                          >
                            {filterSpecies?.type?.name}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Status</TableCell>
                          <TableCell>
                            <span>
                              {filterType?.status === "active" ? (
                                <span className="badge bg-success">Active</span>
                              ) : (
                                <span className="badge bg-danger">
                                  Disabled
                                </span>
                              )}
                            </span>
                            <span
                              style={{
                                margin: "0 24px",
                              }}
                            >
                              <Switch
                                uncheckedIcon={<Offsymbol />}
                                checkedIcon={<OnSymbol />}
                                onColor="#02a499"
                                onChange={() =>
                                  changeStatus(
                                    filterType?.status,
                                    filterType?._id
                                  )
                                }
                                checked={
                                  filterType?.status === "active" ? true : false
                                }
                              />
                            </span>
                            <ToastContainer />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <Card>
                <CardBody>
                  <Typography id="Pageheader">Category Detail</Typography>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell id="tableHeadvalue">
                            Category Name
                          </TableCell>
                          <TableCell>{filterSpecies?.category?.name}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Status</TableCell>
                          <TableCell>
                            <span>
                              {filterCategory?.status === "active" ? (
                                <span className="badge bg-success">Active</span>
                              ) : (
                                <span className="badge bg-danger">
                                  Disabled
                                </span>
                              )}
                            </span>
                            <span
                              style={{
                                margin: "0 24px",
                              }}
                            >
                              <Switch
                                uncheckedIcon={<Offsymbol />}
                                checkedIcon={<OnSymbol />}
                                onColor="#02a499"
                                onChange={() =>
                                  handleCategoryStatus(
                                    filterCategory?.status,
                                    filterCategory?._id
                                  )
                                }
                                checked={
                                  filterCategory?.status === "active"
                                    ? true
                                    : false
                                }
                              />
                            </span>
                            <ToastContainer />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
