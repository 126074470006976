import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import "./promoCode.css"
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  InputGroup,
  Label,
} from "reactstrap"
import { Link, useHistory } from "react-router-dom"
import "flatpickr/dist/themes/material_green.css"
import Flatpickr from "react-flatpickr"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import backend from "api/api"
import { ToastContainer, toast } from "react-toastify"
export default function NewPromoCode() {
  const [state, setState] = useState({
    code: "",
    percent_Off: null,
    max_redemptions: "",
    durationMonth: "",
    amount_off: null,
    duration: "repeating",
    discount: "Percentage",
  })
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const history = useHistory()
  const converttoSeconds = dates => {
    const date = new Date(dates)
    const timestamp = Math.floor(date.getTime() / 1000)
    return timestamp
  }

  console.log("End Date", endDate)

  const getproperDate = originalDateStr => {
    const originalDate = new Date(originalDateStr)
    const options = { year: "numeric", month: "long", day: "numeric" }
    const formattedDate = originalDate.toLocaleDateString("en-US", options)
    return formattedDate
  }
  const properDate = getproperDate(endDate)
  const expiry = converttoSeconds(properDate)
  console.log("properDate", properDate)
  console.log("expiry", expiry)
  const handleStateChange = e => {
    const { name, value } = e.target
    setState(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }
  console.log("state", state)
  const postPromoCode = async () => {
    try {
      if (state.code == "") {
        toast.error("Promo Code is required")
      }
      // else if (state?.discount === "Percentage" && !state.percent_Off) {
      //   toast.error("Percent Off is required")
      // }
      // else if (state.percent_Off < 0) {
      //   toast.error("Percent Off not be  Negative")
      // } else if (state.percent_Off > 100) {
      //   toast.error("Percent_Off less than than 100")
      // }
      // else if (!state.max_redemptions || state.max_redemptions == "") {
      //   toast.error("Max Redemptions is required")
      // }
      else if (state.max_redemptions < 0) {
        toast.error("Max Redemptions not be  Negative")
      }
      // else if (!state.durationMonth || state.durationMonth == "") {
      //   toast.error("Duration Month is required")
      // }
      else if (state.durationMonth < 0) {
        toast.error("Max Redemptions not be  Negative")
      } else if (state.durationMonth > 12) {
        toast.error("Duration Month less than than 12")
      }
      // else if (state?.discount === "Amount" && !state.amount_off) {
      //   toast.error("Amount Off is required")
      // }
      // else if (state.amount_off < 0) {
      //   toast.error("Amount Off not be  Negative")
      // }
      else if (!state.duration || state.duration == "") {
        toast.error("duration is required")
      }
      //  else if (!startDate || startDate == "") {
      //   toast.error("Start Date is required")
      // }
      else if (!endDate || endDate == "") {
        toast.error("End Date is required")
      } else {
        // const body = {
        //   ...state,
        //   // startDate: startDate[0],
        //   expires_at: expiry,
        // }
        if (state?.discount === "Percentage") {
          const body = {
            code: state?.code,
            percent_Off: state?.percent_Off,
            max_redemptions: state?.max_redemptions,
            durationMonth: state?.durationMonth,
            duration: state?.duration,
            discount: state?.discount,
            expires_at: expiry,
          }
          const resp = await backend.post("/api/code/createpromocode", body)
          toast.success(resp?.data?.message)
        } else {
          const body = {
            code: state?.code,
            max_redemptions: state?.max_redemptions,
            durationMonth: state?.durationMonth,
            duration: state?.duration,
            discount: state?.discount,
            expires_at: expiry,
            amount_off: state?.amount_off,
          }
          const resp = await backend.post("/api/code/createpromocode", body)
          toast.success(resp?.data?.message)
        }
        setTimeout(() => history.push("/triptrader/promocode"), 1000)
      }
    } catch (error) {
      toast.error(error?.response?.data.message || error?.message || error)
    }
  }
  const breadcrumbItems = [
    { title: "Dashboard", path: "/triptrader/dashboard" },
    { title: "Create Promo Code", path: "#" },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> New Promo Code| Trip Traders</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
              <Breadcrumbs breadcrumbItems={breadcrumbItems} />

                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active"></li>
                </ol>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12} sm={12} md={6}>
                <label
                  htmlFor="example-text-input"
                  className=" col-form-label"
                >
                  Code
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="code"
                  onChange={handleStateChange}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <label
                  htmlFor="example-text-input"
                  className=" col-form-label"
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  Discount In
                </label>
                <select
                  className="form-select"
                  name="discount"
                  onChange={handleStateChange}
                  defaultValue={"Percentage"}
                >
                  <option value="Percentage">Percentage</option>
                  <option value="Amount">Amount</option>
                </select>
              </Col>
              {state?.discount === "Percentage" ? (
                <Col xs={12} sm={12} md={6}>
                  <label
                    htmlFor="example-text-input"
                    className=" col-form-label"
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    Percent Off
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    name="percent_Off"
                    onChange={handleStateChange}
                  />
                </Col>
              ) : (
                <Col xs={12} sm={12} md={6}>
                  <label
                    htmlFor="example-text-input"
                    className=" col-form-label"
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    Amount Off
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    defaultValue={"Amount"}
                    name="amount_off"
                    onChange={handleStateChange}
                  />
                </Col>
              )}

              <Col xs={12} sm={12} md={6}>
                <label
                  htmlFor="example-text-input"
                  className=" col-form-label"
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  Duration Months
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="durationMonth"
                  onChange={handleStateChange}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <label
                  htmlFor="example-text-input"
                  className=" col-form-label"
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  Max Redemptions
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="max_redemptions"
                  onChange={handleStateChange}
                />
              </Col>

              <Col xs={12} sm={12} md={6}>
                <label
                  htmlFor="example-text-input"
                  className=" col-form-label"
                >
                  Duration
                </label>
                <select
                  className="form-select"
                  // value={tripStatus}
                  name="duration"
                  value={state?.duration}
                  onChange={handleStateChange}
                >
                  {/* <option value="once">once</option> */}
                  <option value="repeating">Repeating</option>
                </select>
              </Col>
            </Row>
            <Row>
              {/* <Col xs={12} sm={12} md={6}>
                <FormGroup className="mb-4 ">
                  <Label>Start Date</Label>
                  <InputGroup>
                    <Flatpickr
                      className="form-control d-block"
                      placeholder="dd M,yyyy"
                      options={{
                        altInput: true,
                        altFormat: "F j, Y",
                        dateFormat: "Y-m-d",
                      }}
                      value={startDate}
                      onChange={e => setStartDate(e)}
                    />
                  </InputGroup>
                </FormGroup>
              </Col> */}
              <Col xs={12} sm={12} md={6}>
                <FormGroup className="mb-4 ">
                  <Label>End Date</Label>
                  <InputGroup>
                    <Flatpickr
                      className="form-control d-block"
                      placeholder="dd M,yyyy"
                      options={{
                        altInput: true,
                        altFormat: "F j, Y",
                        dateFormat: "Y-m-d",
                      }}
                      value={endDate}
                      onChange={e => setEndDate(e)}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col xs={4} sm={3} md={1}>
                <Button
                  color="success"
                  className="btn btn-success waves-effect waves-light"
                  onClick={postPromoCode}
                >
                  Save
                </Button>
                <ToastContainer />
              </Col>
              <Col xs={3} sm={3} md={6}>
                <Link
                  to="/triptrader/promocode"
                  color="secondary"
                  className="btn btn-secondary waves-effect"
                >
                  Cancel
                </Link>{" "}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}
