import React, { useEffect, useState } from "react"
import { useParams, Link, useHistory } from "react-router-dom"
import { MetaTags } from "react-meta-tags"
import { Col, Container, Row, Form, Button } from "reactstrap"
import { toast, ToastContainer } from "react-toastify"

import Breadcrumbs from "components/Common/Breadcrumb"
import backend from "api/api"

const NewTradeTypeOption = () => {
  const { id } = useParams()
  const history = useHistory()
  const [tradeTypes, setTradeTypes] = useState([])
  const [formValues, setFormValues] = useState({
    name: "",
    type: "",
    status: "",
    image: "",
  })
  const [errors, setErrors] = useState({})

  const getTradeTypes = async () => {
    try {
      const response = await backend.get("/api/type")
      setTradeTypes(response?.data?.data || [])
    } catch (error) {
      console.error("error: ", error)
    }
  }

  // Fetch existing trade type option details if in edit mode
  const getTradeTypeOptionDetails = async id => {
    try {
      const response = await backend.get(`/api/tradetypeoptions?_id=${id}`)
      const data = response?.data?.data?.[0]
      setFormValues({
        name: data?.name || "",
        type: data?.type?._id || "",
        status: data?.status || "",
        image: data?.image || "",
      })
    } catch (error) {
      console.error("error: ", error)
    }
  }

  // Validate form before submission
  const validateForm = () => {
    let newErrors = {}
    if (!formValues.name) newErrors.name = "Name is required"
    if (!formValues.type) newErrors.type = "Type is required"
    if (!formValues.image) newErrors.image = "Image is required"
    return newErrors
  }

  // Handle input changes
  const handleInputChange = e => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
  }

  // Handle file change and upload to S3
  const handleFileChange = async e => {
    const file = e.target.files[0]
    if (file) {
      try {
        const config = {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data`,
          },
        }
        const images = new FormData()
        images.append("files", file)
        const response = await backend.post("/api/upload", images, config)
        console.log("response: ", response)
        const imageUrl = response?.data?.data?.[0]?.imgrUrl
        setFormValues({ ...formValues, image: imageUrl })
      } catch (error) {
        console.error("Image upload error: ", error)
      }
    }
  }

  // Handle form submission
  const handleSubmit = async e => {
    e.preventDefault()
    const validationErrors = validateForm()
    setErrors(validationErrors)
    if (Object.keys(validationErrors).length > 0) {
      return
    }

    const payload = {
      name: formValues.name,
      type: formValues.type,
      status: "active",
      image: formValues.image,
    }

    try {
      if (id) {
        // Update existing trade type option
        await backend.put(`/api/tradetypeoptions/${id}`, payload)
        toast.success("Option Updated Successfully")
      } else {
        // Create new trade type option
        await backend.post("/api/tradetypeoptions", payload)
        toast.success("New Option Created Successfully")
      }
      history.push("/triptrader/trade-type-options")
    } catch (error) {
      console.error("Submission error: ", error)
    }
  }

  useEffect(() => {
    getTradeTypes()
    if (id) {
      getTradeTypeOptionDetails(id)
    }
  }, [id])

  const breadcrumbItems = [
    { title: "Dashboard", path: "/triptrader/dashboard" },
    {
      title: id ? "Edit Trade Type Option" : "New Trade Type Option",
      path: "#",
    },
  ]

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <MetaTags>
          <title>
            {id ? "Edit Trade Type Option" : "New Trade Type Option"} | Trip
            Trader
          </title>
        </MetaTags>
        <Container fluid>
          <Row className="page-title-box align-items-center">
            <Col sm="12">
              <Breadcrumbs breadcrumbItems={breadcrumbItems} />
            </Col>
          </Row>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} md={6}>
                <label htmlFor="name" className="col-form-label">
                  Trade Type Option Name
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="name"
                  value={formValues.name}
                  onChange={handleInputChange}
                />
                {errors.name && <p className="text-danger">{errors.name}</p>}
              </Col>

              <Col xs={12} md={6}>
                <label className="col-form-label">Trader Type</label>
                <select
                  className="form-select"
                  name="type"
                  value={formValues.type}
                  onChange={handleInputChange}
                >
                  <option value="">Select Trader Type</option>
                  {tradeTypes?.map(e => (
                    <option key={e?.name} value={e?._id}>
                      {e?.name}
                    </option>
                  ))}
                </select>
                {errors.type && <p className="text-danger">{errors.type}</p>}
              </Col>

              <Col xs={12} md={6}>
                <label className="col-form-label">Trade Type Option Icon</label>
                <div className="d-flex align-items-center">
                  <input
                    type="file"
                    className="form-control me-3"
                    name="image"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                  {formValues.image && (
                    <img
                      src={formValues.image}
                      alt="Uploaded"
                      width="30"
                      height="30"
                      style={{ objectFit: "contain" }}
                    />
                  )}
                </div>
                {errors.image && <p className="text-danger">{errors.image}</p>}
              </Col>

              <Col xs={12}>
                <div className="d-flex align-items-center flex-wrap gap-2 mt-3">
                  <Button
                    color="success"
                    className="btn btn-success waves-effect waves-light"
                    type="submit"
                  >
                    {id ? "Update" : "Save"}
                  </Button>
                  <Link
                    to="/triptrader/trade-type-options"
                    color="secondary"
                    className="btn btn-secondary waves-effect"
                  >
                    Cancel
                  </Link>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default NewTradeTypeOption
