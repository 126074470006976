import React, { useEffect, useState } from "react"
import {
  Box,
  TableContainer,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material"
import { format } from "date-fns"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import backend from "api/api"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useParams } from "react-router-dom"

export default function SubscriptionDetail() {
  let { id } = useParams()
  const { subscribe } = useSelector(state => state?.SubscriptionReducer)

  const filterSubscribe = subscribe?.data?.find(e => e?.id === id)
  console.log(filterSubscribe)
  const timeStampTime = timestamp => {
    const date = new Date(timestamp * 1000)

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")
    const hours = String(date.getHours()).padStart(2, "0")
    const minutes = String(date.getMinutes()).padStart(2, "0")
    const seconds = String(date.getSeconds()).padStart(2, "0")

    const amPm = hours >= 12 ? "PM" : "AM"
    const formattedHours = hours % 12 || 12

    const formattedDate = `${year}-${month}-${day} ${formattedHours}:${minutes}:${seconds} ${amPm}`
    return formattedDate
  }
  const createAt = timeStampTime(filterSubscribe?.created)

  const breadcrumbItems = [
    { title: "Dashboard", path: "/triptrader/dashboard" },
    { title: "Subscriptions", path: "/triptrader/subscription" },
    { title: "Subscription Detail", path: "#" },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Subscription Detail | Trip Trader</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                {/* <h6 className="page-title WebHeader">Admins</h6> */}
                <Breadcrumbs breadcrumbItems={breadcrumbItems} />

                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active"></li>
                </ol>
              </Col>
            </Row>
          </div>
          <Row>
            <Col xs={12} sm={12} md={6}>
              <Card>
                <CardBody>
                  <Typography id="Pageheader">General Information</Typography>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Currency</TableCell>
                          <TableCell>{filterSubscribe?.currency}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Created At</TableCell>
                          <TableCell>{createAt}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <Card>
                <CardBody>
                  <Typography id="Pageheader">Subscription Detail</Typography>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Type</TableCell>
                          <TableCell
                            sx={{
                              textTransform: "capitalize",
                            }}
                          >
                            {filterSubscribe?.interval}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Charges</TableCell>
                          <TableCell>{filterSubscribe.amount}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Status</TableCell>
                          <TableCell>
                            {filterSubscribe?.active === true ? (
                              <span className="badge bg-success">Active</span>
                            ) : (
                              <span className="badge bg-danger">Blocked</span>
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
