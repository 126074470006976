import MetaTags from "react-meta-tags"
import React, { useState } from "react"

import { Container, Row, Col, Button, Label } from "reactstrap"
import { Link, useParams, useHistory } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "chartist/dist/scss/chartist.scss"
import { useSelector } from "react-redux"
import backend from "api/api"

import { toast, ToastContainer } from "react-toastify"
export default function tripEdit() {
  const { id } = useParams()
  const history = useHistory()
  const { trip } = useSelector(state => state.TripReducer)
  const editTrip = trip.find(e => e?._id === id)
  const [editTheTrip, setEditTheTrip] = useState(editTrip)

  const [tripStatus, setTripStatus] = useState(editTrip?.status)
  const [deleteTripText, setDeleteTripText] = useState("")
  const handleTripStatus = e => {
    setTripStatus(e.target.value)
  }
  const UpdateTrip = async () => {
    try {
      if (
        editTheTrip?.suspensionReason == "" ||
        !editTheTrip?.suspensionReason
      ) {
        toast.error("Suspension Reason is required")
      } else {
        const body = {
          status: tripStatus,
          suspensionReason: editTheTrip?.suspensionReason,
        }
        const resp = await backend.put(`api/trip/suspendTrip/${id}`, body)
        toast.success(resp.data.message)
        setTimeout(() => history.push("/triptrader/trips"), 1000)
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message || error)
    }
  }

  const breadcrumbItems = [
    { title: "Dashboard", path: "/triptrader/dashboard" },
    { title: "Trips", path: "/triptrader/trips" },
    { title: "Trip Edit", path: "#" },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Trip Edit | Trip Trader</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItems={breadcrumbItems} />

          <Row>
            <Col xs={12} sm={12} md={6}>
              <label className=" col-form-label">Status</label>

              <select
                className="form-select"
                value={tripStatus}
                onChange={handleTripStatus}
              >
                ,<option value="active">Active</option>
                <option value="suspended">Suspended</option>
              </select>
              {tripStatus === "suspended" ? (
                <div className="mt-3">
                  <Label>Suspension Reason</Label>
                  <br />
                  <textarea
                    className="form-control"
                    id="textAreaExample3"
                    rows="5"
                    value={editTheTrip?.suspensionReason}
                    onChange={e =>
                      setEditTheTrip({
                        ...editTheTrip,
                        suspensionReason: e.target.value,
                      })
                    }
                    placeholder="Type a Reason Please ?"
                  ></textarea>
                </div>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row
            style={{
              marginTop: "1rem",
            }}
          >
            <Col xs={4} sm={3} md={1}>
              <Button
                color="success"
                className="btn btn-success waves-effect waves-light"
                onClick={UpdateTrip}
              >
                Save
              </Button>{" "}
              <ToastContainer />
            </Col>
            <Col xs={3} sm={3} md={6}>
              <Link
                to="/triptrader/trips"
                color="secondary"
                className="btn btn-secondary waves-effect"
              >
                Cancel
              </Link>{" "}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
