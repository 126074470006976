import React, { useState } from "react"
import { Button, Col, Input, Row } from "reactstrap"

const Pagination = ({ totalPages, onPageChange, currentPage }) => {
  const [inputPage, setInputPage] = useState("")

  const handleInputPageChange = e => {
    const pageNumber = parseInt(e.target.value)
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      setInputPage(e.target.value)
      onPageChange(pageNumber)
    } else {
      setInputPage("")
    }
  }

  const handleFirstPage = () => {
    onPageChange(1)
  }

  const handleLastPage = () => {
    onPageChange(totalPages)
  }

  const handlePrevPage = () => {
    onPageChange(prevPage => Math.max(prevPage - 1, 1))
  }

  const handleNextPage = () => {
    onPageChange(prevPage => Math.min(prevPage + 1, totalPages))
  }

  return (
    <Row className="justify-content-md-end justify-content-center align-items-center">
      <Col className="col-md-auto">
        <div className="d-flex gap-1">
          <Button
            color="success"
            className="btn btn-success waves-effect waves-light"
            onClick={handleFirstPage}
            disabled={currentPage === 1}
          >
            {"<<"}
          </Button>
          <Button
            color="success"
            className="btn btn-success waves-effect waves-light"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            {"<"}
          </Button>
        </div>
      </Col>
      <Col className="col-md-auto">
        Page{" "}
        <strong>
          {currentPage} of {totalPages}
        </strong>
      </Col>
      {/* <Col className="col-md-auto">
        <Input
          type="number"
          min="1"
          max={totalPages}
          value={inputPage}
          onChange={handleInputPageChange}
        />
      </Col> */}

      <Col className="col-md-auto">
        <div className="d-flex gap-1">
          <Button
            color="success"
            className="btn btn-success waves-effect waves-light"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            {">"}
          </Button>
          <Button
            color="success"
            className="btn btn-success waves-effect waves-light"
            onClick={handleLastPage}
            disabled={currentPage === totalPages}
          >
            {">>"}
          </Button>
        </div>
      </Col>
    </Row>
  )
}

export default Pagination
