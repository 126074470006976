import React from "react"
import MetaTags from "react-meta-tags"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useSelector } from "react-redux"
import { Col, Row, Container, CardBody, Card } from "reactstrap"
import { format } from "date-fns"
import {
  TableCell,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  Typography,
} from "@mui/material"
import { useParams } from "react-router-dom"

export default function PromoCodeDetail() {
  const { id } = useParams()
  const { code } = useSelector(state => state.PromoCodeReducer)
  const filterCode = code?.data?.find(e => e?.id === id)
  console.log(filterCode)
  const timeStampTime = timestamp => {
    const date = new Date(timestamp * 1000)

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")
    const hours = String(date.getHours()).padStart(2, "0")
    const minutes = String(date.getMinutes()).padStart(2, "0")
    const seconds = String(date.getSeconds()).padStart(2, "0")

    const amPm = hours >= 12 ? "PM" : "AM"
    const formattedHours = hours % 12 || 12

    const formattedDate = `${year}-${month}-${day} ${formattedHours}:${minutes}:${seconds} ${amPm}`
    return formattedDate
  }
  const createdAtTime = timeStampTime(filterCode?.coupon?.created)
  const ExpiryAtTime = timeStampTime(filterCode?.expires_at)
  const breadcrumbItems = [
    { title: "Dashboard", path: "/triptrader/dashboard" },
    { title: "Promo Codes", path: "/triptrader/promocode" },
    { title: "Promo Code Detail", path: "#" },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Promo Code Detail| Trip Traders</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
              <Breadcrumbs breadcrumbItems={breadcrumbItems} />

                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active"></li>
                </ol>
              </Col>
            </Row>
          </div>
          <Row>
            <Col xs={12} sm={12} md={6}>
              <Card>
                <CardBody>
                  <Typography id="Pageheader">General Information</Typography>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell id="tableHeadvalue">
                            Max Redemptions
                          </TableCell>

                          <TableCell>
                            {filterCode?.max_redemptions > 0
                              ? filterCode?.max_redemptions
                              : "-"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">
                            Month Duration
                          </TableCell>
                          <TableCell>
                            {filterCode?.coupon?.duration_in_months > 0
                              ? filterCode?.coupon?.duration_in_months
                              : "-"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Duration</TableCell>
                          <TableCell>{filterCode?.coupon?.duration}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>

            <Col xs={12} sm={12} md={6}>
              <Card>
                <CardBody>
                  <Typography id="Pageheader">
                    Promo Code Information
                  </Typography>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Promo Code</TableCell>
                          <TableCell>{filterCode?.code}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Coupon</TableCell>
                          <TableCell>{filterCode?.coupon?.id}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">
                            Discount in Percentage
                          </TableCell>
                          <TableCell>
                            {filterCode?.coupon?.percent_off}%
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">
                            Discount in Amount
                          </TableCell>
                          <TableCell>
                            {filterCode?.coupon?.amount_off === null
                              ? "-"
                              : filterCode?.coupon?.amount_off}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Status</TableCell>
                          <TableCell>
                            {filterCode?.active === true ? (
                              <span className="badge bg-success">Active</span>
                            ) : (
                              <span className="badge bg-danger">Disabled</span>
                            )}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell id="tableHeadvalue">Start Date</TableCell>
                          <TableCell>{createdAtTime}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Expiry Date</TableCell>
                          <TableCell>{ExpiryAtTime}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
