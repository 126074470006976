import React from "react"
import MetaTags from "react-meta-tags"
import { Link, useParams } from "react-router-dom"

import {
  Container,
  Row,
  Col,
  Button,
  Input,
  Label,
  Card,
  CardBody,
} from "reactstrap"
import {
  Avatar,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material"
import { format } from "date-fns"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import IconButton from "@mui/material/IconButton"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { styled } from "@mui/material/styles"
import { useDispatch, useSelector } from "react-redux"
import CardContent from "@mui/material/CardContent"
import CardActions from "@mui/material/CardActions"
import Collapse from "@mui/material/Collapse"
import { toast, ToastContainer } from "react-toastify"
import backend from "api/api"
import moment from "moment"

const ExpandMore = styled(props => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}))

export default function TripDetail() {
  const [expanded, setExpanded] = React.useState(false)
  const [UnAvailableDates, setUnAvailableDates] = React.useState(false)
  const [excludeSpecificDates, setExcludeSpecificDates] = React.useState(false)
  const dispatch = useDispatch()
  const handleUnAvailableDatesClick = () => {
    setUnAvailableDates(!UnAvailableDates)
  }
  const handleExcludeSpecificDates = () => {
    setExcludeSpecificDates(!excludeSpecificDates)
  }
  const { trip } = useSelector(state => state.TripReducer)
  const { id } = useParams()
  const filterTrip = trip.find(e => e?._id === id)
  console.log(filterTrip)
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  const handleMyTrip = async () => {
    try {
      const resp = await backend.get("/api/trip")
      dispatch({
        type: "TRIP_SUCCESS",
        payload: resp.data.data,
      })
      toast.success(resp?.data?.message)
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message || error)
    }
  }
  const handleIsFeatured = async () => {
    try {
      const value = filterTrip?.isFeatured == true ? false : true

      const body = {
        isFeatured: value,
        featuredDate: new Date(),
      }
      console.log(body)
      const resp = await backend.put(`/api/trip/${id}`, body)
      toast.success(resp.data?.message)
      handleMyTrip()
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message || error)
    }
  }
  const breadcrumbItems = [
    { title: "Dashboard", path: "/triptrader/dashboard" },
    { title: "Trips", path: "/triptrader/trips" },
    { title: "Trip Detail", path: "#" },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Trip Detail | Trip Trader</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItems={breadcrumbItems} />
          <Row className="mb-3">
            <Col
              xs={12}
              sm={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                onClick={handleIsFeatured}
                style={{
                  background: "#3b71ca",
                  color: "white",
                }}
              >
                {filterTrip?.isFeatured === true
                  ? "I am Featured Trip"
                  : "I am not Featured Trip"}
              </Button>
              <ToastContainer />
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={12} md={6}>
              <Card>
                <CardBody>
                  <Typography id="Pageheader">Featured Information</Typography>
                  <Table>
                    <TableRow>
                      <TableCell id="tableHeadvalue">Featured Trip</TableCell>
                      <TableCell>
                        {filterTrip?.isFeatured === true ? "True" : "False"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell id="tableHeadvalue">Date</TableCell>
                      <TableCell>
                        {" "}
                        {format(
                          new Date(filterTrip?.featuredDate),
                          "dd:MMM: Y,h:mm aaa"
                        )}
                      </TableCell>
                    </TableRow>
                  </Table>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <Typography id="Pageheader">General Information</Typography>

                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Posted At</TableCell>
                          <TableCell>
                            {format(
                              new Date(filterTrip?.createdAt),
                              "dd:MMM: Y,h:mm aaa"
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Updated At</TableCell>
                          <TableCell>
                            {format(
                              new Date(filterTrip?.updatedAt),
                              "dd:MMM:Y, h:mm aaa"
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Typography id="Pageheader">Host Detail</Typography>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Host Name</TableCell>
                          <TableCell>
                            {filterTrip?.hostId?.firstName +
                              " " +
                              filterTrip?.hostId?.lastName}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Status</TableCell>
                          <TableCell>
                            {filterTrip?.hostId?.status == "report" ? (
                              <span className="badge bg-warning">Report</span>
                            ) : filterTrip?.hostId?.status === "blocked" ? (
                              <span className="badge bg-danger">Blocked</span>
                            ) : (
                              <span className="badge bg-success">Active</span>
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Typography id="Pageheader">Location Detail</Typography>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Location</TableCell>
                          <TableCell>
                            {filterTrip?.location?.state +
                              " , " +
                              filterTrip?.location?.city}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">City</TableCell>
                          <TableCell>{filterTrip?.location?.city}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">State</TableCell>
                          <TableCell>{filterTrip?.location?.state}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>

            <Col xs={12} sm={12} md={6}>
              <Card>
                <CardBody>
                  <Typography id="Pageheader">Trip Detail</Typography>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Trip Name</TableCell>
                          <TableCell>{filterTrip?.title}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Trade Type</TableCell>
                          <TableCell>{filterTrip?.tradeType}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Category</TableCell>
                          <TableCell>{filterTrip?.category}</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell id="tableHeadvalue">
                            Available From
                          </TableCell>
                          <TableCell>
                            {/* {format(
                              new Date(filterTrip?.availableFrom),
                              "dd:MMM:Y,h:mm aaa"
                            )} */}
                            {moment.utc(filterTrip?.availableFrom).format("'DD MMM YYYY, h:mm:ss a'")}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">
                            Available To
                          </TableCell>
                          <TableCell>
                            {/* {format(
                              new Date(filterTrip?.availableTo),
                              "dd:MMM:Y,h:mm aaa"
                            )} */}
                             {moment.utc(filterTrip?.availableTo).format("'DD MMM YYYY, h:mm:ss a'")}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Species</TableCell>
                          <TableCell
                            sx={{
                              textTransform: "capitalize",
                            }}
                          >
                            {filterTrip?.species}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Duration</TableCell>
                          <TableCell>
                            {filterTrip?.duration.value +
                              " " +
                              filterTrip?.duration.title}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Status</TableCell>
                          <TableCell>
                            {filterTrip?.status === "active" ? (
                              <span className="badge bg-success">Active</span>
                            ) : (
                              <span className="badge bg-danger">Suspended</span>
                            )}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell id="tableHeadvalue">
                            Return Activity
                          </TableCell>
                          <TableCell>{filterTrip?.returnActivity}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">
                            Accept Trade Offers{" "}
                          </TableCell>
                          <TableCell>
                            {filterTrip?.acceptTradeOffers === true
                              ? "True"
                              : "False"}
                          </TableCell>
                        </TableRow>
                        {filterTrip?.status === "suspended" ? (
                          <TableRow>
                            <TableCell id="tableHeadvalue">
                              Suspension Reason{" "}
                            </TableCell>
                            <TableCell>
                              {filterTrip?.suspensionReason}
                            </TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Typography id="Pageheader">UnAvailable Days</Typography>
                  <Card sx={{ maxWidth: "100%" }}>
                    <CardActions disableSpacing>
                      <Typography id="tableHeadvalue">
                        All UnAvailable Dates
                      </Typography>
                      <ExpandMore
                        expand={UnAvailableDates}
                        onClick={handleUnAvailableDatesClick}
                        aria-expanded={UnAvailableDates}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </CardActions>
                    <Collapse
                      in={UnAvailableDates}
                      timeout="auto"
                      unmountOnExit
                    >
                      <CardContent>
                        {filterTrip?.unAvailableDays?.allUnavailableDates
                          ?.length > 0
                          ? filterTrip?.unAvailableDays?.allUnavailableDates.map(
                              (e, i) => (
                                <Typography
                                  key={i}
                                  sx={{ textAlign: "center" }}
                                  paragraph
                                >
                                  {format(new Date(e), "dd:MMM:Y, h:mm aaa")}
                                </Typography>
                              )
                            )
                          : ""}
                      </CardContent>
                    </Collapse>
                  </Card>

                  <Card sx={{ maxWidth: "100%" }}>
                    <CardActions disableSpacing>
                      <Typography id="tableHeadvalue">
                        UnAvailable Days Of Week{" "}
                      </Typography>

                      <ExpandMore
                        expand={excludeSpecificDates}
                        onClick={handleExcludeSpecificDates}
                        aria-expanded={excludeSpecificDates}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </CardActions>
                    <Collapse
                      in={excludeSpecificDates}
                      timeout="auto"
                      unmountOnExit
                    >
                      <CardContent>
                        {filterTrip?.unAvailableDays?.unavailableDaysOfWeek
                          ?.length > 0
                          ? filterTrip?.unAvailableDays?.unavailableDaysOfWeek.map(
                              e => (
                                <Typography
                                  sx={{ textAlign: "center" }}
                                  paragraph
                                >
                                  {/* {format(new Date(e), "dd:MMM:Y, h:mm aaa")} */}
                                  {moment.utc(e).format("'DD MMM YYYY, h:mm:ss a'")}
                                </Typography>
                              )
                            )
                          : ""}
                      </CardContent>
                    </Collapse>
                  </Card>

                  <TableContainer>
                    <Table>
                      {filterTrip?.unAvailableDays?.repeatEvery?.endRepeatOn
                        ?.length > 0 &&
                      filterTrip?.unAvailableDays?.repeatEvery?.title.length >
                        0 ? (
                        <TableBody>
                          <TableRow>
                            <TableCell id="tableHeadvalue">Week Days</TableCell>
                            <TableCell>
                              {filterTrip?.unAvailableDays?.dayWeekText +
                                " " +
                                filterTrip?.unAvailableDays
                                  ?.excludeDateText}{" "}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell id="tableHeadvalue">
                              Repeat Every
                            </TableCell>
                            <TableCell>
                              {filterTrip?.unAvailableDays?.repeatEvery?.title +
                                ", " +
                                moment.utc(filterTrip?.unAvailableDays?.repeatEvery?.endRepeatOn).format("'DD MMM YYYY, h:mm:ss a'")
                                // format(
                                //   new Date(
                                //     filterTrip?.unAvailableDays?.repeatEvery?.endRepeatOn
                                //   ),
                                //   "dd:MMM:Y, h:mm aaa"
                                // )
                                
                                }
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : (
                        ""
                      )}
                    </Table>
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12}>
              <Card sx={{ maxWidth: 345 }}>
                <CardActions disableSpacing id="Pageheader"   onClick={handleExpandClick}>
                  Photos
                  <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <CardContent>
                    <Grid container>
                      {filterTrip?.photos?.map(e => (
                        <Grid item xs={12} sm={12} md={6} lg={4} className="p-2">
                          <div className="text-center bg-black w-100 h-100">
                            <img
                              src={e}
                              style={{
                                // marginTop: "1rem",
                                // width: "70%",
                                height: "400px",
                              }}
                              className=" object-fit-contain w-100"
                            />
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </CardContent>
                </Collapse>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
