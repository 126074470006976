const initialState = { reported: [] }

export default function ReportReducer(state = initialState, action) {
  switch (action.type) {
    case "REPORTEDUSER_SUCCESS":
      return {
        ...state,
        reported: action.payload,
      }
    case "REPORTED_TRIP_SUCCESS":
      return {
        ...state,
        reported: action.payload,
      }

    default:
      return state
  }
}
