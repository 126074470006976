import backend from "api/api"
import Breadcrumbs from "components/Common/Breadcrumb"
import React from "react"
import { MetaTags } from "react-meta-tags"
import { Button, Col, Container, Row } from "reactstrap"
import { toast, ToastContainer } from "react-toastify"
import { Link, useHistory } from "react-router-dom"
import { useState } from "react"
export default function NewCategory() {
  const [state, setState] = useState({ categoryName: "" })
  const history = useHistory()
  const handleCategory = e => {
    const { name, value } = e.target

    setState(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }
  const postCategory = async () => {
    const trimmedCategory = state.categoryName.trim()
    try {
      const body = {
        name: trimmedCategory,
        status: "active",
      }
      if (!trimmedCategory) {
        toast.error("Category Name is Required")
      } else {
        const resp = await backend.post("/api/category", body)
        setTimeout(() => history.push("/triptrader/catagory"), 1000)
        toast.success(resp?.data?.message)
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message || error)
    }
  }
  const breadcrumbItems = [
    { title: "Dashboard", path: "/triptrader/dashboard" },
    { title: "Create Category", path: "#" },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> New Category | Trip Trader</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                {/* <h6 className="page-title WebHeader">Freemium Customers </h6> */}
                <Breadcrumbs breadcrumbItems={breadcrumbItems} />

                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active"></li>
                </ol>
              </Col>
            </Row>
          </div>
          <Row>
            <Col xs={12} sm={12} md={6}>
              <label
                htmlFor="example-text-input"
                className=" col-form-label"
              >
                New Category
              </label>
              <input
                className="form-control"
                type="text"
                name="categoryName"
                onChange={handleCategory}
              />
            </Col>
          </Row>
          <Row
            style={{
              marginTop: "1rem",
            }}
          >
            <Col xs={4} sm={3} md={1}>
              <Button
                color="success"
                className="btn btn-success waves-effect waves-light"
                onClick={postCategory}
              >
                Save
              </Button>{" "}
              <ToastContainer />
            </Col>
            <Col xs={3} sm={3} md={6}>
              <Link
                to="/triptrader/catagory"
                color="secondary"
                className="btn btn-secondary waves-effect"
              >
                Cancel
              </Link>{" "}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
