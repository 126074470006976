import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"
import { toast, ToastContainer } from "react-toastify"
import backend from "api/api"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { CircularProgress, Tooltip } from "@material-ui/core"
import { Link } from "react-router-dom"
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"
import Pagination from "components/Common/Pagination"

const OngoingTrips = () => {
  const [ongoingTrips, setOngoingTrips] = useState([])
  const [tripOffers, setTripOffers] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [menu, setMenu] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const getOngoingTrips = async (page = 1) => {
    setIsLoading(true)
    try {
      const resp = await backend.get(
        `/api/trip/tripsWithOffers?page=${page}&limit=5`
      )
      setOngoingTrips(resp?.data?.data)
      setTotalPages(resp?.data?.pages)
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message || error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleTripOffers = item => {
    setTripOffers(item?.offers)
    setMenu(true)
  }

  const handlePageChange = page => {
    setCurrentPage(page)
  }

  useEffect(() => {
    getOngoingTrips(currentPage)
  }, [currentPage])
  const breadcrumbItems = [
    { title: "Dashboard", path: "/triptrader/dashboard" },
    { title: "Ongoing Trips", path: "#" },
  ]
  return (
    <div className="page-content">
      <ToastContainer />
      <MetaTags>
        <title>Ongoing Trips | Trip Trader</title>
      </MetaTags>
      <Container fluid>
        <Row className="page-title-box align-items-center ">
          <Col sm="12">
          <Breadcrumbs breadcrumbItems={breadcrumbItems} />
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active"></li>
            </ol>
          </Col>
          <Col xs="12">
            <Card style={{ minHeight: 335 }}>
              <CardBody>
                {isLoading ? (
                  <div className="d-flex flex-column align-items-center h-100 w-100">
                    <CircularProgress />
                  </div>
                ) : (
                  <Table hover responsive>
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Trade Type</th>
                        <th>Status</th>
                        <th>Duration</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ongoingTrips?.length > 0 ? (
                        ongoingTrips?.map((el, index) => {
                          return (
                            <tr key={index}>
                              <td>{el?.title}</td>
                              <td>{el?.tradeType}</td>
                              <td>
                                {el?.status === "active" ? (
                                  <span className="badge bg-success">
                                    Active
                                  </span>
                                ) : (
                                  <span className="badge bg-danger">
                                    Suspended
                                  </span>
                                )}
                              </td>
                              <td>
                                {el?.duration?.value +
                                  " " +
                                  el?.duration?.title}
                              </td>
                              <td>
                                <Link
                                  to={`#`}
                                  onClick={() => handleTripOffers(el)}
                                >
                                  <Tooltip title="View Status">
                                    <VisibilityOutlinedIcon />
                                  </Tooltip>
                                </Link>
                              </td>
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan={5}>
                            <Alert className="text-center">
                              No Ongoing Trips Found
                            </Alert>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                )}
              </CardBody>
            </Card>
          </Col>
          <Col xs="12">
            <Pagination
              totalPages={totalPages}
              onPageChange={handlePageChange}
              currentPage={currentPage}
            />
          </Col>
        </Row>
      </Container>
      <Modal isOpen={menu} toggle={() => setMenu(!menu)} size="lg" scrollable>
        <ModalHeader toggle={() => setMenu(!menu)}>
          Trip Trade Offers Status
        </ModalHeader>
        <ModalBody>
          <Table hover responsive>
            <thead>
              <tr>
                <th>Offered To</th>
                <th>Offered From</th>
                <th>Offered Trip</th>
                <th>Offered Trip Duration</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {tripOffers?.length > 0 && tripOffers?.[0]?.status ? (
                tripOffers?.map((el, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {el?.offeredTo?.firstName} {el?.offeredTo?.lastName}
                      </td>
                      <td>
                        {el?.offeredBy?.firstName} {el?.offeredBy?.lastName}
                      </td>
                      <td>{el?.hostTrip?.title}</td>
                      <td>
                        {el?.hostTrip?.duration?.value +
                          " " +
                          el?.hostTrip?.duration?.title}
                      </td>
                      <td>
                        {el?.status === "confirmed" && (
                          <span className="badge bg-success">Confirmed</span>
                        )}
                        {el?.status === "declined" && (
                          <span className="badge bg-danger">Declined</span>
                        )}
                        {el?.status === "pending" && (
                          <span className="badge bg-info">Pending</span>
                        )}
                        {el?.status === "suspended" && (
                          <span className="badge bg-danger">Suspended</span>
                        )}
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={5}>
                    <Alert className="text-center">No Offers Found</Alert>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setMenu(!menu)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default OngoingTrips
