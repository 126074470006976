import { CircularProgress, Grid, Tooltip, Typography } from "@mui/material"
import { Box } from "@mui/material"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import "./User.css"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import servicesIcon1 from "../../assets/images/services-icon/01.png"
import servicesIcon2 from "../../assets/images/services-icon/02.png"
import servicesIcon3 from "../../assets/images/services-icon/03.png"
import servicesIcon4 from "../../assets/images/services-icon/04.png"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt"
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"
import "chartist/dist/scss/chartist.scss"
import SearchTable from "../../components/SearchTable/searchTable"
import { useDispatch, useSelector } from "react-redux"
import backend from "api/api"
import { toast, ToastContainer } from "react-toastify"
import moment from "moment"
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}
export default function SubscribeCustomer() {
  const [value, setValue] = React.useState(0)
  const [loading, setLoading] = useState(false)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const dispatch = useDispatch()
  let rows = []

  const { users } = useSelector(state => state.GetUserReducer)
  const subscribeUser = users.filter(e => e?.subscriptionStatus === "paid")
  const getUsers = async () => {
    try {
      setLoading(true)
      const resp = await backend.get("/api/user")
      setLoading(false)
      dispatch({
        type: "GET_USER_DATA",
        payload: resp.data.data,
      })
    } catch (error) {
      toast.error(error.response?.data.message || error?.message || error)
    }
  }
  const myAction = params => {
    return (
      <>
        <Link to={`/triptrader/useredit/${params.userId}`}>
          <Tooltip title="Edit Customer">
            <EditLocationAltIcon />
          </Tooltip>
        </Link>

        <Link to={`/triptrader/userdetail/${params.userId}`}>
          <Tooltip title="View Customer">
            <VisibilityOutlinedIcon />
          </Tooltip>
        </Link>
      </>
    )
  }

  const columnhead = [
    { 
      title: "Cust. #", 
      render: rowData => rowData.tableData.id + 1, 
    },
    {
      title: "Name",
      field: "name",
    },
    { title: "Email", field: "email" },
    { title: "Phone", field: "phone" },
    { title: "Joined At", field: "joining" },
    { title: "Current Subscription", field: "subscription" },
    { title: "Identity Status", field: "identityStatus" },
    { title: "Status", field: "status" },
    {
      title: "Action",
      field: "Action",
      render: myAction,
    },
  ]

  if (subscribeUser) {
    subscribeUser?.map((e, i) => {
      rows.push({
        // Srno: i + 1,
        userId: e?._id,
        name: `${e?.firstName} ${e?.lastName}`,
        email: e?.email,
        phone: <span className={!e?.phone && "badge bg-warning"}>{e?.phone ? e?.phone : "N/A"}</span>,
        joining: e?.createdAt && moment.utc(e?.createdAt).format(`DD-MMM-YY`),
        subscription: `${e?.subscription?.status === "PremiumC" ? "Cancelled Premium" : e?.subscription?.status} (${e?.subscription?.title})`,
        identityStatus:
        e?.identityStatus === "appliedFor" ? <span className="badge bg-warning">Applied For</span> : e?.identityStatus === "pending" ? (
            <span className="badge bg-warning">N/A</span>
          ) : e?.identityStatus === "notVerified" ? (
            <span className="badge bg-danger">Not Verified</span>
          ) : (
            <span className="badge bg-success">Verified</span>
          ),
        status:
          e?.status == "report" ? (
            <span className="badge bg-warning">Report</span>
          ) : e?.status === "blocked" ? (
            <span className="badge bg-danger">Blocked</span>
          ) : (
            <span className="badge bg-success">Active</span>
          ),
      })
    })
  }
  const activeUser = rows.filter(e => e?.status.props.children === "Active")
  const blockedUser = rows.filter(e => e?.status.props.children === "Blocked")
  const reportUser = rows.filter(e => e?.status.props.children === "Report")

  useEffect(() => {
    getUsers()
  }, [])

  const breadcrumbItems = [
    { title: "Dashboard", path: "/triptrader/dashboard" },
    { title: "Subscribed Customer", path: "#" },
  ]

  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Subscribe Customer| Trip Traders</title>
          </MetaTags>
          <Container fluid>
            <div className="page-title-box">
              <Row className="align-items-center">
                <Col md={8}>
                  <Breadcrumbs breadcrumbItems={breadcrumbItems} />
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item active"></li>
                  </ol>
                </Col>
              </Row>
            </div>
            <Row>
              <Col xl={3} md={6}>
                <Card
                  className="mini-stat text-white"
                  style={{ backgroundColor: "#283c2e" }}
                >
                  <CardBody>
                    <div className="mb-4">
                      <div className="float-start mini-stat-img me-4">
                        <img src={servicesIcon1} alt="" />
                      </div>
                      <h5 className="font-size-14 text-uppercase mt-0">
                        Subscribed Customers
                      </h5>
                      <h4 className="fw-medium font-size-24">
                        {subscribeUser.length}{" "}
                      </h4>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={3} md={6}>
                <Card
                  className="mini-stat text-white"
                  style={{ backgroundColor: "#283c2e" }}
                >
                  <CardBody>
                    <div className="mb-4">
                      <div className="float-start mini-stat-img me-4">
                        <img src={servicesIcon2} alt="" />
                      </div>
                      <h5 className="font-size-14 text-uppercase mt-0">
                        Active Customers
                      </h5>
                      <h4 className="fw-medium font-size-24">
                        {activeUser.length}{" "}
                      </h4>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={3} md={6}>
                <Card
                  className="mini-stat text-white"
                  style={{ backgroundColor: "#283c2e" }}
                >
                  <CardBody>
                    <div className="mb-4">
                      <div className="float-start mini-stat-img me-4">
                        <img src={servicesIcon3} alt="" />
                      </div>
                      <h5 className="font-size-15 text-uppercase mt-0">
                        Blocked Customers
                      </h5>
                      <h4 className="fw-medium font-size-24">
                        {blockedUser.length}{" "}
                      </h4>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={3} md={6}>
                <Card
                  className="mini-stat text-white"
                  style={{ backgroundColor: "#283c2e" }}
                >
                  <CardBody>
                    <div className="mb-4">
                      <div className="float-start mini-stat-img me-4">
                        <img src={servicesIcon4} alt="" />
                      </div>
                      <h5 className="font-size-14 text-uppercase mt-0 ">
                        Reported Customers
                      </h5>
                      <h4 className="fw-medium font-size-24">
                        {reportUser.length}
                      </h4>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {loading ? (
              <CircularProgress />
            ) : (
              <Grid
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  overFlow: "scroll !important",
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="All" {...a11yProps(0)} />
                  <Tab label="Active" {...a11yProps(1)} />
                  <Tab label="Blocked" {...a11yProps(2)} />
                  <Tab label="Reported" {...a11yProps(3)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                  <SearchTable columns={columnhead} data={rows} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <SearchTable columns={columnhead} data={activeUser} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <SearchTable columns={columnhead} data={blockedUser} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <SearchTable columns={columnhead} data={reportUser} />
                </TabPanel>
              </Grid>
            )}
            <ToastContainer />
          </Container>
        </div>
      </React.Fragment>
    </div>
  )
}
