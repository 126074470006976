import React from "react"
import ReactApexChart from "react-apexcharts"

const TripsStatusStats = ({ data }) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]

  const confirmedTrips = Array(12).fill(0)
  const completedTrips = Array(12).fill(0)

  if (data) {
    data.confirmed_trips_count.forEach(trip => {
      const monthIndex = months.indexOf(trip.month)
      if (monthIndex !== -1) {
        confirmedTrips[monthIndex] = trip.count
      }
    })

    data.completed_trips_count.forEach(trip => {
      const monthIndex = months.indexOf(trip.month)
      if (monthIndex !== -1) {
        completedTrips[monthIndex] = trip.count
      }
    })
  }

  const chartData = {
    series: [
      {
        name: "Confirmed Trips",
        data: confirmedTrips || [],
      },
      {
        name: "Completed Trips",
        data: completedTrips || [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 230,
      },
      title: {
        text: "Active Trips Stats",
        align: "center",
        floating: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      colors: ["#283C2E", "#FF4B4B"],
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: months,
      },
      yaxis: {
        title: {
          text: "Number of Trips",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " trips"
          },
        },
      },
    },
  }

  return (
    <div>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={380}
      />
    </div>
  )
}

export default TripsStatusStats
