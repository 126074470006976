import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import Switch from "react-switch"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt"
import SearchTable from "../../components/SearchTable/searchTable"
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"
import { useDispatch, useSelector } from "react-redux"
import { toast, ToastContainer } from "react-toastify"
import servicesIcon1 from "assets/images/services-icon/01.png"
import servicesIcon2 from "assets/images/services-icon/02.png"
import servicesIcon3 from "assets/images/services-icon/03.png"
import backend from "api/api"
import { CircularProgress } from "@material-ui/core"
import { Tooltip } from "@mui/material"
export default function PromoCode() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const { code } = useSelector(state => state.PromoCodeReducer)
  const activeCode = code?.data?.filter(e => e?.active == true)
  const inActiveCode = code?.data?.filter(e => e?.active == false)
  const rows = []
  if (code) {
    code?.data?.map((e, i) =>
      rows.push({
        srNo: i + 1,
        pcodeId: e?.id,
        coupon: e?.coupon?.id,
        times_redeemed: e?.coupon?.times_redeemed,
        promoCode: e?.code,
        status:
          e?.active === true ? (
            <span className="badge bg-success">Active</span>
          ) : (
            <span className="badge bg-danger">Disable</span>
          ),
      })
    )
  }
  console.log("the response of the promocode", code?.data)
  const myAction = params => {
    return (
      <>
        {/* <Link to={`/triptrader/promoedit/${params.pcodeId}`}>
          <Tooltip title="Edit Promo Code">
            <EditLocationAltIcon />
          </Tooltip>
        </Link> */}

        <Link to={`/triptrader/promodetail/${params.pcodeId}`}>
          <Tooltip title="View Promo Code">
            <VisibilityOutlinedIcon />
          </Tooltip>
        </Link>
      </>
    )
  }
  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    )
  }
  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    )
  }

  const changePromoCodeStatus = async (status, id) => {
    try {
      const value = status.props.children === "Active" ? false : true
      const body = {
        status: value,
      }
      const resp = await backend.patch(`/api/code/updatepromocode/${id}`, body)
      getPromoCode()
      toast.success(resp.data.message)
    } catch (error) {
      console.log("line 106", error?.message)
      toast.error(
        error?.raw?.message ||
          error?.response?.data.message ||
          error?.message ||
          error
      )
    }
  }
  const handleChangeStatus = params => {
    return (
      <>
        <Switch
          uncheckedIcon={<Offsymbol />}
          checkedIcon={<OnSymbol />}
          onColor="#02a499"
          onChange={() => changePromoCodeStatus(params.status, params.pcodeId)}
          checked={params?.status.props.children === "Active" ? true : false}
        />
        <ToastContainer />
      </>
    )
  }
  const columns = [
    {
      title: "Sr. No#",
      field: "srNo",
    },
    {
      title: "Promo Code",
      field: "promoCode",
    },
    {
      title: "Coupon",
      field: "coupon",
    },
    {
      title: "Redeeme Times",
      field: "times_redeemed",
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "Change Status",
      field: "changeStatus",
      render: handleChangeStatus,
    },
    {
      title: "Actions",
      field: "actions",
      render: myAction,
    },
  ]

  const getPromoCode = async () => {
    try {
      setLoading(true)
      const resp = await backend.get("/api/code/allpromocode")
      console.log("response of the Data", resp)
      setLoading(false)
      dispatch({
        type: "PROMO_CODE_SUCCESS",
        payload: resp.data.data,
      })
    } catch (error) {
      toast.error(error?.response?.data.message || error?.message || error)
    }
  }
  useEffect(() => {
    getPromoCode()  
  }, [])
  const breadcrumbItems = [
    { title: "Dashboard", path: "/triptrader/dashboard" },
    { title: "Promo Codes", path: "#" },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Promo Code| Trip Traders</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
              <Breadcrumbs breadcrumbItems={breadcrumbItems} />

                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active"></li>
                </ol>
              </Col>
            </Row>
            <Row>
              <Col xl={4} md={6}>
                <Card
                  className="mini-stat text-white"
                  style={{ backgroundColor: "#283c2e" }}
                >
                  <CardBody>
                    <div className="mb-4">
                      <div className="float-start mini-stat-img me-4">
                        <img src={servicesIcon1} alt="" />
                      </div>
                      <h5 className="font-size-14 text-uppercase mt-0">
                        Total Promo Code
                      </h5>
                      <h4 className="fw-medium font-size-24">
                        {code?.data?.length}
                      </h4>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4} md={6}>
                <Card
                  className="mini-stat  text-white"
                  style={{ backgroundColor: "#283c2e" }}
                >
                  <CardBody>
                    <div className="mb-4">
                      <div className="float-start mini-stat-img me-4">
                        <img src={servicesIcon2} alt="" />
                      </div>
                      <h5 className="font-size-14 text-uppercase mt-0 ">
                        Active Promo Code
                      </h5>
                      <h4 className="fw-medium font-size-24">
                        {activeCode?.length}
                      </h4>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4} md={6}>
                <Card
                  className="mini-stat  text-white"
                  style={{ backgroundColor: "#283c2e" }}
                >
                  <CardBody>
                    <div className="mb-4">
                      <div className="float-start mini-stat-img me-4">
                        <img src={servicesIcon3} alt="" />
                      </div>
                      <h4 className="font-size-14 text-uppercase mt-0 ">
                        InActive Promo Code
                      </h4>
                      <h4 className="fw-medium font-size-24">
                        {inActiveCode?.length}
                      </h4>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {loading ? (
              <CircularProgress />
            ) : (
              <SearchTable columns={columns} data={rows} />
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}
