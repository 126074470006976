import React from "react"
import ReactApexChart from "react-apexcharts"

const UserStats = ({ data }) => {
  const chartOptions = {
    series: [
      {
        data: data
          ? [
              data?.users_count,
              data?.premium_users_count,
              data?.premiumc_users_count,
              data?.freemium_users_count,
            ]
          : [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 380,
      },
      plotOptions: {
        bar: {
          barHeight: "100%",
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: "bottom",
          },
        },
      },
      colors: ["#283C2E", "#FF4B4B", "#d4526e", "#3C6B49"],
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        style: {
          colors: ["#000"],
        },
        formatter: function (val, opt) {
          return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
        },
        offsetX: 0,
        dropShadow: {
          enabled: true,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      xaxis: {
        categories: [
          "Total",
          "Premium",
          "Cancelled Premium",
          "Freemium",
        ],
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      title: {
        text: "User Stats",
        align: "center",
        floating: false,
      },
      tooltip: {
        theme: "dark",
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function () {
              return ""
            },
          },
        },
      },
    },
  }
  return (
    <div>
      <ReactApexChart
        options={chartOptions.options}
        series={chartOptions.series}
        type="bar"
        height={380}
      />
    </div>
  )
}

export default UserStats
