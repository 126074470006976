import React, { useState } from "react"
import { Container, Row, Col, Button } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import MetaTags from "react-meta-tags"
import { toast, ToastContainer } from "react-toastify"
import { Link, useHistory } from "react-router-dom"
import backend from "api/api"
import { useSelector } from "react-redux"
export default function ChangePassword() {
  const { login } = useSelector(state => state.UserAuthReducer)
  const history = useHistory()
  const [state, setState] = useState({
    curr_pass:"",
    newPassword: "",
    confirmPassword: "",
  })
  const handleChangePassword = e => {
    const { name, value } = e.target
    setState(prevState => ({ ...prevState, [name]: value }))
  }
  const postChangePassword = async () => {
    try {
      const body = {
        curr_pass:state?.curr_pass,
        newPassword: state.newPassword,
        confirmPassword: state.confirmPassword,
        email: login?.email,
      }
      console.log(body)
      if(!state.curr_pass){
        toast.error("Current Password is required")
      }
      else if (!state.newPassword) {
        toast.error("New Password is required")
      } else if (!state.confirmPassword) {
        toast.error("Confirm Password is required")
      } else if (state.newPassword !== state.confirmPassword) {
        toast.error("New Passwor is not matches with Confirm Password ")
      } else {
        const resp = await backend.put("/api/user/changePassword", body)
        toast.success(resp?.data.message)
        setTimeout(() => history.push("/triptrader/admin"), 1000)
      }
    } catch (error) {
      toast.error(error?.response?.data.message || error.message || error)
    }
  }
  const breadcrumbItems = [
    { title: "Dashboard", path: "/triptrader/dashboard" },
    { title: "Change Password", path: "#" },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Change Password | Trip Trader</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                {/* <h6 className="page-title WebHeader">Admins</h6> */}
                <Breadcrumbs breadcrumbItems={breadcrumbItems} />
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12} sm={12} md={6}>
                <label
                  htmlFor="example-text-input"
                  className=" col-form-label"
                >
                  Current Password
                 </label>
                <input
                  className="form-control"
                  type="password"
                  name="curr_pass"
                  value={state.curr_pass}
                  onChange={handleChangePassword}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <label
                  htmlFor="example-text-input"
                  className=" col-form-label"
                >
                  New Password
                </label>
                <input
                  className="form-control"
                  type="password"
                  name="newPassword"
                  value={state.newPassword}
                  onChange={handleChangePassword}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <label
                  htmlFor="example-text-input"
                  className=" col-form-label"
                >
                  Confirm Password
                </label>
                <input
                  className="form-control"
                  type="password"
                  name="confirmPassword"
                  value={state.confirmPassword}
                  onChange={handleChangePassword}
                />
              </Col>
            </Row>
            <Row
              style={{
                marginTop: "1rem",
              }}
            >
              <Col xs={12} sm={12} md={1}>
                <Button
                  color="success"
                  className="btn btn-success waves-effect waves-light"
                  onClick={postChangePassword}
                >
                  Save
                </Button>{" "}
                <ToastContainer />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Link
                  to="/triptrader/admin"
                  color="secondary"
                  className="btn btn-secondary waves-effect"
                >
                  Cancel
                </Link>{" "}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}
