import React, { useEffect, useState } from "react"
import {
  Box,
  TableContainer,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material"
import { format } from "date-fns"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import backend from "api/api"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useParams } from "react-router-dom"
import Switch from "react-switch"
import { toast, ToastContainer } from "react-toastify"

export default function StateDetail() {
  let { id } = useParams()
  const { state } = useSelector(state => state?.StateReducer)
  const dispatch = useDispatch()
  const filterState = state.find(e => e?._id === id)
  const getAllStates = async () => {
    const resp = await backend.get("/api/state")
    dispatch({
      type: "STATE_SUCCESS",
      payload: resp?.data.data,
    })
  }
  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    )
  }
  const handleStateStatus = async () => {
    try {
      const value = filterState?.status === "active" ? "disabled" : "active"
      const body = {
        status: value,
      }
      const resp = await backend.put(`/api/state/${filterState?._id}`, body)
      toast.success(resp?.data?.message)
      getAllStates()
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message || error)
    }
  }

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    )
  }
  useEffect(() => {
    getAllStates()
  }, [])
  const breadcrumbItems = [
    { title: "Dashboard", path: "/triptrader/dashboard" },
    { title: "States", path: "/triptrader/state" },
    { title: "State Detail", path: "#" },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>State Detail | Trip Trader</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
              <Breadcrumbs breadcrumbItems={breadcrumbItems} />

                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active"></li>
                </ol>
              </Col>
            </Row>
          </div>
          <Row>
            <Col xs={12} sm={12} md={6}>
              <Card>
                <Typography id="Pageheader"> General Information</Typography>
                <CardBody>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Posted By</TableCell>
                          <TableCell>
                            {format(
                              new Date(filterState?.createdAt),
                              "dd:MMM:Y, h:mm aaa"
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Updated By</TableCell>
                          <TableCell>
                            {format(
                              new Date(filterState?.updatedAt),
                              "dd:MMM:Y, h:mm aaa"
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <Card>
                <CardBody>
                  <Typography id="Pageheader">State Detail</Typography>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell id="tableHeadvalue">State Name</TableCell>
                          <TableCell>{filterState?.name}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Status</TableCell>
                          <TableCell>
                            <span>
                              {filterState?.status === "active" ? (
                                <span className="badge bg-success">Active</span>
                              ) : (
                                <span className="badge bg-danger">
                                  Disabled
                                </span>
                              )}
                            </span>
                            <span
                              style={{
                                margin: "0 2rem",
                              }}
                            >
                              <Switch
                                uncheckedIcon={<Offsymbol />}
                                checkedIcon={<OnSymbol />}
                                onColor="#02a499"
                                onChange={handleStateStatus}
                                checked={
                                  filterState?.status === "active"
                                    ? true
                                    : false
                                }
                              />
                            </span>
                            <ToastContainer />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
