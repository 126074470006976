import React, { useState } from "react"
import { format } from "date-fns"
import MetaTags from "react-meta-tags"
import { Link, useParams, useHistory } from "react-router-dom"
import { Container, Row, Col, Button, Label } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useSelector } from "react-redux"
import { toast, ToastContainer } from "react-toastify"
import backend from "api/api"

export default function ReviewEdit() {
  let { id } = useParams()
  let history = useHistory()
  const { review } = useSelector(state => state.ReviewReducer)
  const editReview = review?.find(e => e?._id === id)
  const [editTheReview, setEditTheReview] = useState(editReview)
  const [reviewStatus, setReviewStatus] = useState(editReview.status)
  const handleReviewStatus = e => {
    setReviewStatus(e?.target.value)
  }
  const postReview = async () => {
    try {
      const body = {
        status: reviewStatus,
      }
      const resp = await backend.put(`/api/review/${id}`, body)
      setTimeout(() => history.push("/triptrader/review"), 1000)
      toast.success(resp.data.message)
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message || error)
    }
  }
  const breadcrumbItems = [
    { title: "Dashboard", path: "/triptrader/dashboard" },
    { title: "Reviews", path: "/triptrader/review" },
    { title: "Review Edit", path: "#" },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Review Edit | Trip Trader</title>
        </MetaTags>
        <Container fluid>
        <Breadcrumbs breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col xs={12} sm={12} md={6}>
              <label className=" col-form-label">Status</label>
              <select
                className="form-select"
                value={reviewStatus}
                onChange={handleReviewStatus}
              >
                <option value="active">Active</option>
                <option value="notGiven">Not Given</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
                <option value="dispute">Dispute</option>
              </select>
            </Col>
          </Row>
          <Row
            style={{
              marginTop: "1rem",
            }}
          >
            <Col xs={4} sm={3} md={1}>
              <Button
                color="success"
                className="btn btn-success waves-effect waves-light"
                onClick={postReview}
              >
                Save
              </Button>{" "}
              <ToastContainer />
            </Col>
            <Col xs={3} sm={3} md={6}>
              <Link
                to="/triptrader/review"
                color="secondary"
                className="btn btn-secondary waves-effect"
              >
                Cancel
              </Link>{" "}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
