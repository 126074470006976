import React, { useEffect, useMemo, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import backend from "api/api"
import { ToastContainer, toast } from "react-toastify"
import SearchTable from "components/SearchTable/searchTable"
import { Link } from "react-router-dom"
import { CircularProgress, Tooltip } from "@material-ui/core"
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt"
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import servicesIcon1 from "../../assets/images/services-icon/01.png"
import servicesIcon2 from "../../assets/images/services-icon/02.png"
import servicesIcon3 from "../../assets/images/services-icon/03.png"
import { useDispatch, useSelector } from "react-redux"

const ReportedTrips = () => {
  // const [reportedTrips, setReportedTrips] = useState([])
  const { reported: reportedTrips } = useSelector(state => state.ReportReducer)
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const handleReportedTrips = async () => {
    setIsLoading(true)
    try {
      const response = await backend.get("/api/reporttrips")
      // setReportedTrips(response.data.data)
      dispatch({
        type: "REPORTED_TRIP_SUCCESS",
        payload: response.data.data,
      })
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message || error)
    } finally {
      setIsLoading(false)
    }
  }
  const tData = useMemo(() => {
    return reportedTrips?.map(trip => ({
      title: trip?.tripId?.title,
      tradeType: trip?.tripId?.tradeType,
      status: (
        <span
          className={`badge bg-success text-capitalize ${
            trip?.status === "active" ? "bg-success" : "bg-danger"
          }`}
        >
          {trip?.status}
        </span>
      ),
      reason: trip?.reason?.substring(0, 300),
      action: (
        <>
          <Link to={`/triptrader/reported-trip/${trip?._id}`}>
            <Tooltip title="View Trips">
              <VisibilityOutlinedIcon />
            </Tooltip>
          </Link>
        </>
      ),
    }))
  }, [reportedTrips])
  const headers = [
    "Title",
    "TradeType",
    "Reported Status",
    "Reported Reason",
    "Action",
  ]
  const columnsData = useMemo(() => {
    if (tData.length === 0) return []
    return Object.keys(tData?.[0])?.map((item, index) => ({
      title: headers?.[index],
      field: item,
    }))
  }, [tData])

  const cardsData = [
    {
      title: " Reported Trips",
      count: reportedTrips?.length ?? 0,
      pic: servicesIcon1,
    },
    {
      title: " Active Reports",
      count:
        reportedTrips?.filter(trip => trip?.status === "active")?.length ?? 0,
      pic: servicesIcon2,
    },
    {
      title: " Resolved Reports",
      count:
        reportedTrips?.filter(trip => trip?.status === "resolved")?.length ?? 0,
      pic: servicesIcon3,
    },
  ]

  useEffect(() => {
    handleReportedTrips()
  }, [])
  const breadcrumbItems = [
    { title: "Dashboard", path: "/triptrader/dashboard" },
    { title: "Reported Trips", path: "#" },
  ]
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <MetaTags>
          <title>Reported Trips | Trip Trader</title>
        </MetaTags>
        <Container fluid>
          <Row className="page-title-box align-items-center ">
            <Col sm="12">
            <Breadcrumbs breadcrumbItems={breadcrumbItems} />

              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active"></li>
              </ol>
            </Col>
            {cardsData?.map((el, index) => {
              return (
                <Col xl={4} md={6} key={index}>
                  <Card
                    className="mini-stat text-white"
                    style={{ backgroundColor: "#283c2e" }}
                  >
                    <CardBody>
                      <div className="mb-4">
                        <div className="float-start mini-stat-img me-4">
                          <img src={el?.pic} alt={`pic-${index}`} />
                        </div>
                        <h5 className="font-size-15 text-uppercase mt-0">
                          {el?.title}
                        </h5>
                        <span>{el?.count}</span>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )
            })}
          </Row>
          <Row>
            <Col sm="12">
              {isLoading ? (
                <CircularProgress />
              ) : (
                <SearchTable columns={columnsData} data={tData} />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ReportedTrips
