import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap"
import {
  TableContainer,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { format } from "date-fns"
import backend from "api/api"
import { toast } from "react-toastify"

const ReportedTripsDetail = () => {
  const [menu, setMenu] = useState(false)
  const { reported } = useSelector(state => state.ReportReducer)
  const { login } = useSelector(state => state.UserAuthReducer)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const { id } = useParams()

  // State for form inputs and validation messages
  const [formData, setFormData] = useState({ status: "", remarks: "" })
  const [errors, setErrors] = useState({})

  const reportedTrip = reported.find(e => e?._id === id)
  const handleReportedTrips = async () => {
    try {
      const response = await backend.get("/api/reporttrips")
      dispatch({
        type: "REPORTED_TRIP_SUCCESS",
        payload: response.data.data,
      })
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message || error)
    }
  }

  const validate = () => {
    const errors = {}
    if (!formData.status) errors.status = "Status is required"
    if (!formData.remarks) errors.remarks = "Remarks are required"
    return errors
  }

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const validationErrors = validate()
    setErrors(validationErrors)
    if (Object?.keys(validationErrors).length === 0) {
      setIsLoading(true)
      const payload = {
        status: formData.status === "resolved" ? "active" : formData.status,
        suspendedby: formData.status === "resolved" ? null : login?._id,
        reported_item_id: id,
        remarks: formData.remarks,
        reported_item_status: formData.status,
      }
      try {
        const response = await backend.put(
          `api/trip/suspendTripByAdmin/${reportedTrip?.tripId?._id}`,
          payload
        )
        console.log(response)
        handleReportedTrips()
      } catch (error) {
        toast.error(error?.response?.data?.message || error?.message || error)
      } finally {
        setIsLoading(false)
        setMenu(false)
      }
    }
  }

  useEffect(() => {
    handleReportedTrips()
  }, [])
  const breadcrumbItems = [
    { title: "Dashboard", path: "/triptrader/dashboard" },
    { title: "Reported Trips", path: "/triptrader/reported-trips" },
    { title: "Reported Trip Detail", path: "#" },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Reported Trip Detail | Trip Trader</title>
        </MetaTags>
        <Container fluid>
        <Breadcrumbs breadcrumbItems={breadcrumbItems} />
          <Row className="row-gap-3">
            <Col sm="12">
              <Button color="danger" onClick={() => setMenu(!menu)}>
                Take Action
              </Button>
              <Modal isOpen={menu} toggle={() => setMenu(!menu)}>
                <ModalHeader toggle={() => setMenu(!menu)}>
                  Take Action for Report
                </ModalHeader>
                <ModalBody>
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <Label for="status">Report Action</Label>
                      <Input
                        id="status"
                        name="status"
                        type="select"
                        value={formData.status}
                        onChange={handleChange}
                      >
                        <option value="">Select Action</option>
                        <option value="suspended">Suspend</option>
                        <option value="resolved">Resolve</option>
                      </Input>
                      {errors?.status && (
                        <div className="text-danger">{errors.status}</div>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="remarks">Remarks</Label>
                      <Input
                        id="remarks"
                        name="remarks"
                        type="textarea"
                        rows="3"
                        value={formData.remarks}
                        onChange={handleChange}
                      />
                      {errors?.remarks && (
                        <div className="text-danger">{errors.remarks}</div>
                      )}
                    </FormGroup>
                    <Button
                      color="secondary"
                      type="submit"
                      disabled={isLoading}
                    >
                      Submit
                    </Button>
                  </Form>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={() => setMenu(!menu)}>
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <Card>
                <Typography id="Pageheader"> General Information</Typography>
                <CardBody>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Posted At</TableCell>
                          <TableCell>
                            {format(
                              new Date(reportedTrip?.createdAt),
                              "dd:MMM:Y, h:mm aaa"
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Updated At</TableCell>
                          <TableCell>
                            {format(
                              new Date(reportedTrip?.updatedAt),
                              "dd:MMM:Y, h:mm aaa"
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <Card>
                <CardBody>
                  <Typography id="Pageheader">Reported Trip</Typography>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Trip Name</TableCell>
                          <TableCell>{reportedTrip?.tripId?.title}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">
                            Trip Durations
                          </TableCell>
                          <TableCell>
                            {reportedTrip?.tripId?.duration.value +
                              " " +
                              reportedTrip?.tripId?.duration.title}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">
                            Trip Status
                          </TableCell>
                          <TableCell>
                            {reportedTrip?.tripId?.status}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Link
                    to={`/triptrader/tripdetail/${reportedTrip?.tripId?._id}`}
                  >
                    More Detail
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <Card>
                <CardBody>
                  <Typography id="Pageheader">Report Detail</Typography>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Reason</TableCell>
                          <TableCell>{reportedTrip?.reason}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Status</TableCell>
                          <TableCell sx={{ textTransform: "capitalize" }}>
                            {reportedTrip?.status}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <Card>
                <CardBody>
                  <Typography id="Pageheader">Report By</Typography>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Name</TableCell>
                          <TableCell>
                            {reportedTrip?.reportby?.firstName +
                              " " +
                              reportedTrip?.reportby?.lastName}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Email</TableCell>
                          <TableCell>{reportedTrip?.reportby?.email}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ReportedTripsDetail
