import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import servicesIcon1 from "../../assets/images/services-icon/01.png"
import servicesIcon2 from "../../assets/images/services-icon/02.png"
import servicesIcon3 from "../../assets/images/services-icon/03.png"
import "chartist/dist/scss/chartist.scss"
import { withTranslation } from "react-i18next"
import LineChart from "pages/AllCharts/chartjs/linechart"
import BarChart from "pages/AllCharts/chartjs/barchart"
import { useDispatch, useSelector } from "react-redux"
import backend from "api/api"
import { toast } from "react-toastify"
import TripsActionsStats from "pages/AllCharts/TripsActionsStats"
import TripsStatusStats from "pages/AllCharts/TripsStatusStats"
import UserStats from "pages/AllCharts/UserStats"
import { CircularProgress } from "@material-ui/core"
const Dashboard = props => {
  const dispatch = useDispatch()
  const { stats } = useSelector(state => state?.CustomerStatsReducer)
  const { tripStates } = useSelector(state => state?.TripStatsReducer)
  const { users } = useSelector(state => state.GetUserReducer)
  const { trip } = useSelector(state => state.TripReducer)
  const [chartsData, setChartsData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const webadmin = users.filter(e => e?.role === "admin")
  const handleCustomerChart = async () => {
    try {
      const resp = await backend.get("/api/dashboard/customers")
      dispatch({
        type: "CUSTOMER_STATS",
        payload: resp?.data?.doc,
      })
    } catch (error) {
      toast.error(error.response?.data?.message || error?.message || error)
    }
  }
  const getUsers = async () => {
    try {
      const resp = await backend.get("/api/user")

      dispatch({
        type: "GET_USER_DATA",
        payload: resp.data.data,
      })
    } catch (error) {
      toast.error(error.response?.data.message || error?.message || error)
    }
  }
  const handleMyTrip = async () => {
    try {
      const resp = await backend.get("/api/trip")
      dispatch({
        type: "TRIP_SUCCESS",
        payload: resp.data.data,
      })
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message || error)
    }
  }
  const handleTripChart = async () => {
    const resp = await backend.get("/api/dashboard/trips")
    dispatch({
      type: "TRIPSTATS_SUCCESS",
      payload: resp?.data?.doc,
    })
  }

  const tripStatusStats = async () => {
    setIsLoading(true)
    try {
      const promises = [
        backend.get("/api/offer/offerStats"),
        backend.get("/api/trip/stats"),
        backend.get("/api/trip/stats"),
        backend.get("/api/trip/tripStats"),
        backend.get("/api/user/userStats"),
      ]
      const responses = await Promise.all(promises)
      const [response1, response2, response3, response4, response5] =
        responses.map(response => response.data)
      const combinedStats = {
        offerStats: response1,
        tripStats1: response2,
        tripStats2: response3,
        tripStats3: response4,
        userStats: response5,
      }
      setChartsData(combinedStats)
      console.log("combinedStats: ", combinedStats)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleCustomerChart()
    handleTripChart()
    getUsers()
    handleMyTrip()
    tripStatusStats()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Trip Trader</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Dashboard</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Welcome to Trip Trader Dashboard
                  </li>
                </ol>
              </Col>
            </Row>
          </div>
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Col xl={4} md={6}>
              <Card
                className="mini-stat text-white"
                style={{ backgroundColor: "#283c2e" }}
              >
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon1} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 ">
                      Total User
                    </h5>
                    <h4 className="fw-medium font-size-24">{users?.length}</h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={4} md={6}>
              <Card
                className="mini-stat text-white"
                style={{ backgroundColor: "#283c2e" }}
              >
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon2} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 ">
                      Admins
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {webadmin?.length}{" "}
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={4} md={6}>
              <Card
                className="mini-stat text-white"
                style={{ backgroundColor: "#283c2e" }}
              >
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon3} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 ">
                      Total Trips
                    </h5>
                    <h4 className="fw-medium font-size-24">{trip?.length} </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="row-gap-3">
            <Col sm="6">
              <Card className="h-100">
                <CardBody>
                  {isLoading ? (
                    <div
                      style={{ height: 380 }}
                      className="d-flex justify-content-center  align-items-center "
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    <TripsActionsStats data={chartsData?.tripStats1} />
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col sm="6">
              <Card className="h-100">
                <CardBody>
                  {isLoading ? (
                    <div
                      style={{ height: 380 }}
                      className="d-flex justify-content-center  align-items-center "
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    <TripsStatusStats data={chartsData?.offerStats} />
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col sm="12">
              <Card className="h-100">
                <CardBody>
                  {isLoading ? (
                    <div
                      style={{ height: 380 }}
                      className="d-flex justify-content-center  align-items-center "
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    <UserStats data={chartsData?.userStats?.data} />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4">Customers Stats</CardTitle>
                  <LineChart customerStats={stats} />
                </CardBody>
              </Card>
            </Col>

            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4">Trip Stats</CardTitle>
                  <BarChart tripData={tripStates} />
                </CardBody>
              </Card>
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Dashboard)
