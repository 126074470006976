import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { Col, Container, Row } from "reactstrap"
import Switch from "react-switch"
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt"
import { Link } from "react-router-dom"
import { Tooltip, CircularProgress } from "@mui/material"

import backend from "api/api"
import Breadcrumbs from "components/Common/Breadcrumb"
import SearchTable from "components/SearchTable/searchTable"

const TradeTypeOptions = () => {
  const [tradeTypeOptions, setTradeTypeOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getTradeTypeOptions = async loading => {
    try {
      if (loading) {
        setIsLoading(true)
      }
      const response = await backend.get("/api/tradetypeoptions")
      setTradeTypeOptions(response?.data?.data)
    } catch (error) {
      console.error("error: ", error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleStatus = async (id, status) => {
    try {
      await backend.put(`/api/tradetypeoptions/${id}`, { status })
      getTradeTypeOptions(true)
    } catch (error) {
      console.log("error: ", error)
    }
  }

  // Define columns for the MaterialTable
  const columns = [
    {
      title: "Image",
      field: "image",
      render: rowData => (
        <img
          src={rowData?.image}
          width="30"
          height="30"
          style={{ objectFit: "contain" }}
        />
      ),
    },
    { title: "Name", field: "name" },
    { title: "Type", field: "type.name" },
    {
      title: "Status",
      field: "status",
      render: rowData =>
        rowData?.status === "active" ? (
          <span className="badge bg-success">Active</span>
        ) : (
          <span className="badge bg-danger">Disable</span>
        ),
    },
    {
      title: "Change Status",
      field: "status",
      render: rowData => (
        <Switch
          onChange={() =>
            handleStatus(
              rowData._id,
              rowData.status === "active" ? "disabled" : "active"
            )
          }
          checked={rowData.status === "active"}
          onColor="#02a499"
          offColor="#D32F2F"
        />
      ),
    },
    {
      title: "Actions",
      field: "actions",
      render: rowData => (
        <Link to={`/triptrader/edit-trade-type-option/${rowData?._id}`}>
          <Tooltip title="Edit">
            <EditLocationAltIcon />
          </Tooltip>
        </Link>
      ),
    },
  ]

  // Map the API data to the format that MaterialTable expects
  const data = tradeTypeOptions.map(option => ({
    _id: option?._id,
    image: option?.image,
    name: option?.name,
    type: option?.type,
    status: option?.status,
  }))

  useEffect(() => {
    getTradeTypeOptions(true)
  }, [])

  const breadcrumbItems = [
    { title: "Dashboard", path: "/triptrader/dashboard" },
    { title: "Trade Type Options", path: "#" },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Trade Type Options | Trip Trader</title>
        </MetaTags>
        <Container fluid>
          <Row className="page-title-box align-items-center">
            <Col sm="12">
              <Breadcrumbs breadcrumbItems={breadcrumbItems} />
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              {isLoading ? (
                <CircularProgress />
              ) : (
                <SearchTable columns={columns} data={data} />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TradeTypeOptions
